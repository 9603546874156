<template>
    <v-card
      :class="theme.opacityFormBox ? 'cts-login-opacity' : ''"
      class="pa-5 cts-login-box"
    >
        <v-row
          class="ma-0 pa-0"
          dense
        >
            <v-col
              class="mt-0 mb-4 mx-0 pa-0"
              cols="12"
            >
                <h1 class="cts-font-size-2 font-weight-regular">{{$vuetify.lang.t('$vuetify.loginTitleText')}}</h1>
            </v-col>
        </v-row>
        <v-row
          v-if="!expired_password"
          class="ma-0"
          dense
        >
            <v-form
              v-if="!two_factor_mfa"
              ref="form"
              v-model="valid"
              class="cts-form-login"
              lazy-validation
            >
                <v-text-field
                  id="username"
                  v-model="input.username"
                  :aria-label="$vuetify.lang.t('$vuetify.user')"
                  :placeholder="$vuetify.lang.t('$vuetify.user')"
                  :prepend-inner-icon="const_icons.USER"
                  :rules="usernameRules"
                  autocomplete="on"
                  name="username"
                  outlined
                  puid="username"
                  required
                />
                <v-text-field
                  id="password"
                  v-model="input.password"
                  :aria-label="$vuetify.lang.t('$vuetify.password')"
                  :placeholder="$vuetify.lang.t('$vuetify.password')"
                  :prepend-inner-icon="const_icons.PASSWORD"
                  :rules="passwordRules"
                  :type="'password'"
                  autocomplete="on"
                  name="password"
                  outlined
                  puid="password"
                  @keyup.enter="submit"
                />
                <a @click="rememberPassword()">
          <span
            id="login-text-forget_password"
            class="cts-login-rememberPassword cts-link"
          >{{$vuetify.lang.t('$vuetify.rememberPassword')}}</span>
                </a>
                <v-btn
                  id="login_button"
                  :loading="loading"
                  class="mt-4 cts-button-login px-0"
                  style="width: 100px;"
                  @click="submit"
                >
                    {{$vuetify.lang.t('$vuetify.login')}}
                </v-btn>
                <v-expand-transition>
                    <v-row
                      v-if="errorMessage != null"
                      class="ma-0 mt-2 cts-login-error-message justify-center"
                      dense
                    >
            <span
              id="login_error_message"
              class="caption"
            >{{errorMessage}}</span>
                    </v-row>
                </v-expand-transition>
            </v-form>

            <v-form
              v-else
              ref="form_two_factor"
              v-model="valid_mfa"
              class="cts-form-login"
              lazy-validation
              @submit.prevent="submit"
            >
                <v-col>
                    <v-row
                      class="ma-0"
                      dense
                    >
                        <v-col
                          class="pa-0"
                          style="max-width: 340px"
                        >
                            <p v-if="qr_image != null">{{$vuetify.lang.t('$vuetify.mfa_configuration_required')}}</p>
                            <p v-else>{{$vuetify.lang.t('$vuetify.twoFactorText')}}</p>
                        </v-col>
                    </v-row>
                    <v-row
                      class="ma-0"
                      dense
                    >
                        <v-col
                          cols="12"
                        >
                            <v-img
                              :src="qr_image"
                              class="mx-auto"
                              width="200"
                            />
                        </v-col>
                    </v-row>
                </v-col>

                <v-row
                  class="ma-0 my-2 justify-center"
                  dense
                >
                    <v-col>
                        <v-text-field
                          id="mfa"
                          v-model="input.mfa"
                          :aria-label="$vuetify.lang.t('$vuetify.code')"
                          :placeholder="$vuetify.lang.t('$vuetify.code')"
                          :prepend-inner-icon="const_icons.PASSWORD"
                          :rules="mfaRules"
                          autocomplete="on"
                          name="mfa"
                          outlined
                          puid="mfa"
                          required
                        />
                    </v-col>
                </v-row>
                <v-btn
                  id="two_factor_button"
                  :loading="loading"
                  class="mt-4 cts-button-login"
                  @click="submit"
                >
                    {{$vuetify.lang.t('$vuetify.continue')}}
                </v-btn>
                <v-expand-transition>
                    <v-row
                      v-if="errorMessage != null"
                      class="ma-0 mt-2 cts-login-error-message justify-center"
                      dense
                    >
            <span
              id="login_error_message"
              class="caption"
            >{{errorMessage}}</span>
                    </v-row>
                </v-expand-transition>
            </v-form>
        </v-row>
        <v-row
          v-else
          class="ma-0"
          dense
        >
            <v-col
              class="cts-login-box-subtitle pb-4"
              cols="12"
            >
                <div
                  class="cts-user-management-error-message"
                  style="min-width: 300px"
                >
                    <p>{{$vuetify.lang.t('$vuetify.expiredPasswordResponse')}}</p>
                </div>
            </v-col>
            <v-col cols="12">

                <v-btn
                  class="my-4 cts-button-login"
                  @click="goLogin()"
                >
                    {{$vuetify.lang.t('$vuetify.back')}}
                </v-btn>
            </v-col>
        </v-row>
    </v-card>

</template>

<script>
import const_global from '@/constants/global'
import const_icons from '@/constants/icons'
import {showToast} from "@/util/utils"
import {
    NAMESPACE as AUTHENTICATION_NAMESPACE,
    STATE as AUTHENTICATION_STATE,
    ACTIONS as AUTHENTICATION_ACTIONS
} from '@/constants/vuex/authentication'
import {userLogin} from '@/services/userServices'
import {redirect} from '@/util/router_utils'
import {WITHOUT_SUBACCOUNTS, REMEMBER_PASSWORD} from '@/constants/router/routes'

export default {
    name: 'LoginBox',
    props: {
        theme: String
    },
    data() {
        return {
            const_icons,
            valid: true,
            valid_mfa: true,
            errorMessage: null,
            isLoggingIn: false,
            visible: false,
            two_factor_mfa: false,
            qr_image: null,
            input: {
                username: '',
                password: '',
                mfa: null
            },
            usernameRules: [
                v => !!v || this.$vuetify.lang.t('$vuetify.userRequired'),
                v => /.+@.+\..+/.test(v) || this.$vuetify.lang.t('$vuetify.emailNotValid')
            ],
            passwordRules: [
                v => !!v || this.$vuetify.lang.t('$vuetify.passwordRequired')
            ],
            mfaRules: [
                v => !!v || this.$vuetify.lang.t('$vuetify.codeRequired')
            ],
        }
    },
    created() {
        this.$store.dispatch(AUTHENTICATION_NAMESPACE + '/' + AUTHENTICATION_ACTIONS.A_CHECKING_LOGIN, false)
        this.expired_password = false
    },
    computed: {
        loading() {
            return this.isLoggingIn
        },
        expired_password: {
            get() {
                return this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_EXPIRED_PASSWORD]
            },
            set(value) {
                this.$store.dispatch(AUTHENTICATION_NAMESPACE + '/' + AUTHENTICATION_ACTIONS.A_SET_EXPIRED_PASSWORD, value)
            }
        }
    },
    methods: {
        async submit() {
            this.errorMessage = null
            this.loader = true
            this.isLoggingIn = true
            this.errorMessage = !(!this.two_factor_mfa && this.$refs.form.validate()) || (this.two_factor_mfa && this.$refs.form_two_factor.validate()) ? this.$vuetify.lang.t('$vuetify.toContinueFillFields') : null

            const userLoginResponse = await userLogin(this.input.username.toLowerCase(), this.input.password, this.input.mfa)
            this.isLoggingIn = false

            if (userLoginResponse.data.error == 0) {
                const userData = userLoginResponse.data.user
                this.$store.dispatch(`${AUTHENTICATION_NAMESPACE}/${AUTHENTICATION_ACTIONS.A_SET_LOGIN_DATA}`, userData)

                userData.group[const_global.SUBACCOUNTS].length != 0 ? window.location.reload() : redirect(WITHOUT_SUBACCOUNTS)
            }

            if (userLoginResponse.data.error == 4) {
                this.$store.dispatch(`${AUTHENTICATION_NAMESPACE}/${AUTHENTICATION_ACTIONS.A_SET_EXPIRED_PASSWORD}`, true)
            }

            if (userLoginResponse.data.error == 2) {
                this.two_factor_mfa = true
                if (userLoginResponse.data.extra) {
                    this.qr_image = userLoginResponse.data.extra
                }
            }

            if (userLoginResponse.data.message != null && userLoginResponse.data.error !== 0) {
                showToast(true, this.$vuetify.lang.t(`$vuetify.${userLoginResponse.data.message}`))
            }

        },
        setErrorMessage(error_message) {
            this.errorMessage = error_message
        },
        rememberPassword() {
            redirect(REMEMBER_PASSWORD)
        },
        goLogin() {
            this.expired_password = false
        }
    }
}
</script>
<style>

.cts-form-login p {
    font-size: 13px;
}
</style>
